<template>
	<li class="nav-user" role="none">
		<a
			id="account-dropdown-toggle"
			href="#"
			class="nav-link"
			role="menuitem"
			aria-haspopup="true"
			aria-label="User account menu"
			:aria-expanded="show.toString()"
			@click.prevent.stop="onDropdownClick"
		>
			<span class="user-avatar">
				<img :src="avatar" class="user-avatar-img" />
			</span>
			<span class="account-user">
				{{ name }}
			</span>
		</a>
		<div
			v-show="show"
			aria-labelledby="account-dropdown-toggle"
			class="dropdown-menu"
		>
			<a href="#" class="dropdown-item" @click.prevent="onPartnerSwitch">
				<font-awesome-icon
					aria-hidden="true"
					class="btn-icon mr-2"
					:icon="['fas', 'exchange-alt']"
				/>
				Switch Partner
			</a>
			<a
				target="_blank"
				class="dropdown-item"
				@click.prevent="openIntercomHelp"
			>
				<font-awesome-icon
					aria-hidden="true"
					class="btn-icon mr-2"
					:icon="['fas', 'life-ring']"
				/>
				Help
			</a>

			<a href="#" class="dropdown-item" @click.prevent="onSignoutClick">
				<font-awesome-icon
					aria-hidden="true"
					class="btn-icon mr-2"
					:icon="['far', 'sign-out']"
				/>
				Sign out
			</a>
		</div>
		<switch-partner-panel :show="partner" @close="partner = false" />
	</li>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import SwitchPartnerPanel from './Panels/SwitchPartnerPanel'

/**
 * The escape numeric key code.
 *
 * @type {Number}
 */
const ESCAPE_KEY_CODE = 27

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		SwitchPartnerPanel,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the current authenticated user's avatar image.
		 *
		 * @return {String}
		 */
		avatar() {
			return this.user.profile_image_retina || ''
		},

		/**
		 * Get the current authenticated user's name.
		 *
		 * @return {String}
		 */
		name() {
			if (this.user.first_name && this.user.last_name) {
				return `${this.user.first_name} ${this.user.last_name}`
			}

			return ''
		},

		...mapState('auth', {
			user: state => state.user || null,
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Add the escape keydown event listeners.
		 *
		 * @return {void}
		 */
		addEventListeners() {
			document.addEventListener('click', this.onOutsideClick)
			document.addEventListener('keydown', this.onEscapeKeyDown)
		},

		openIntercomHelp() {
			window.Intercom('showSpace', 'help')
		},

		/**
		 * Determine if the given keycode is the escape key.
		 *
		 * @param {Number} keyCode
		 * @return {Boolean}
		 */
		isEscapeKeyCode(keyCode) {
			return keyCode === ESCAPE_KEY_CODE
		},

		/**
		 * Handle the dropdown click event.
		 *
		 * @return {void}
		 */
		onDropdownClick() {
			this.show = !this.show

			this.show ? this.addEventListeners() : this.removeEventListeners()
		},

		/**
		 * Handle the escape keydown event.
		 *
		 * @returns {void}
		 */
		onEscapeKeyDown(event) {
			if (this.show && this.isEscapeKeyCode(event.keyCode)) {
				this.show = false

				this.removeEventListeners()
			}
		},

		/**
		 * Handle a global click event outside of the dropdown toggler.
		 *
		 * @return {void}
		 */
		onOutsideClick() {
			if (this.show) {
				this.show = false

				this.removeEventListeners()
			}
		},

		/**
		 * Handle the partner switch event.
		 *
		 * @return {void}
		 */
		onPartnerSwitch() {
			this.partner = true
		},

		/**
		 * Handle the sign out link click event.
		 *
		 * @return {void}
		 */
		onSignoutClick() {
			this.logout()

			this.$alert.success('logout')

			this.$router.push({ path: '/' })
		},

		/**
		 * Remove the global event listeners.
		 *
		 * @return {void}
		 */
		removeEventListeners() {
			document.removeEventListener('keydown', this.onEscapeKeyDown)
			document.removeEventListener('click', this.onOutsideClick)
		},

		...mapActions({ logout: 'auth/logout' }),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'AccountDropdown',

	/**
	 * The component's before destroy lifecycle hook.
	 *
	 * @return {void}
	 */
	beforeDestroy() {
		this.removeEventListeners()
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			partner: false,
			show: false,
		}
	},
}
</script>
