<template>
	<li class="nav-notifications" role="none">
		<div
			v-show="show"
			aria-labelledby="notifications-dropdown-toggle"
			class="dropdown-menu dropdown-menu-wide"
		>
			<a
				v-if="hasUpdate"
				href="https://claruscare.com/product-updates"
				target="_blank"
				class="dropdown-item flex-col"
				@click="onNewReleaseClick"
			>
				<div class="flex w-100 font-medium text-sm text-gray-700">
					A new version of OMD has been released.
				</div>
				<div class="flex justify-end w-100 text-xs text-gray-600">
					View Release Notes
				</div>
			</a>

			<a
				v-else
				href="https://claruscare.com/product-updates"
				target="_blank"
				class="dropdown-item flex-col"
			>
				<div class="flex w-100 font-medium text-sm text-gray-700">
					You are using the most recent version of OMD.
				</div>
				<div class="flex justify-end w-100 text-xs text-gray-600">
					View Release Notes
				</div>
			</a>
		</div>
	</li>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { version } from '../../package.json'

/**
 * The escape numeric key code.
 *
 * @type {Number}
 */
const ESCAPE_KEY_CODE = 27

export default {
	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Determine if new updates have been added to the code base.
		 *
		 * @return {Boolean}
		 */
		hasUpdate() {
			if (!this.version) {
				return false
			}

			if (!this.localVersion) {
				return true
			}

			const [localMajor, localMinor] = this.localVersion.split('.')
			const [currentMajor, currentMinor] = this.version.split('.')

			return localMajor !== currentMajor || localMinor !== currentMinor
		},

		...mapState('auth', {
			localVersion: state => state.version || null,
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Add the escape keydown event listeners.
		 *
		 * @return {void}
		 */
		addEventListeners() {
			document.addEventListener('click', this.onOutsideClick)
			document.addEventListener('keydown', this.onEscapeKeyDown)
		},

		/**
		 * Determine if the given keycode is the escape key.
		 *
		 * @param {Number} keyCode
		 * @return {Boolean}
		 */
		isEscapeKeyCode(keyCode) {
			return keyCode === ESCAPE_KEY_CODE
		},

		/**
		 * Handle the dropdown click event.
		 *
		 * @return {void}
		 */
		onDropdownClick() {
			this.show = !this.show

			this.show ? this.addEventListeners() : this.removeEventListeners()
		},

		/**
		 * Handle the escape keydown event.
		 *
		 * @returns {void}
		 */
		onEscapeKeyDown(event) {
			if (this.show && this.isEscapeKeyCode(event.keyCode)) {
				this.show = false

				this.removeEventListeners()
			}
		},

		/**
		 * Handle a global click event outside of the dropdown toggler.
		 *
		 * @return {void}
		 */
		onOutsideClick() {
			if (this.show) {
				this.show = false

				this.removeEventListeners()
			}
		},

		/**
		 * Handle the on new release click event.
		 *
		 * @return {void}
		 */
		onNewReleaseClick() {
			if (this.hasUpdate) {
				this.setVersion()
			}
		},

		/**
		 * Remove the global event listeners.
		 *
		 * @return {void}
		 */
		removeEventListeners() {
			document.removeEventListener('keydown', this.onEscapeKeyDown)
			document.removeEventListener('click', this.onOutsideClick)
		},

		...mapActions('auth', {
			setVersion: 'setVersion',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'NotificationsDropdown',

	/**
	 * The component's before destroy lifecycle hook.
	 *
	 * @return {void}
	 */
	beforeDestroy() {
		this.removeEventListeners()
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			show: false,
			version,
		}
	},
}
</script>
