var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"sidebar",attrs:{"id":"sidebar","aria-label":"Main navigation"}},[_c('router-link',{staticClass:"sidebar-brand",attrs:{"aria-current":"page","data-cy":"dashboard-link","role":"link","to":{
			name: 'app.dashboard',
		}}},[_c('span',{staticClass:"sr-only"},[_vm._v("Go to dashboard")]),_c('img',{attrs:{"src":require("@/assets/img/logo.png"),"alt":"Clarus Care logo"}})]),_c('ul',{staticClass:"sidebar-items"},[_c('li',{staticClass:"sidebar-item"},[_c('router-link',{staticClass:"sidebar-link",attrs:{"aria-current":"page","role":"link","data-cy":"calls-link","data-tooltip":"Calls","data-position":"right","to":{
					name: 'app.calls',
				}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'phone-volume']}}),_c('span',{staticClass:"sr-only"},[_vm._v("Calls")])],1)],1),(_vm.hasPagingEnabled)?_c('li',{staticClass:"sidebar-item"},[_c('router-link',{staticClass:"sidebar-link",attrs:{"data-cy":"paging-link","aria-current":"page","data-tooltip":"Paging","data-position":"right","to":{
					name: 'app.paging',
				}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'pager']}}),_c('span',{staticClass:"sr-only"},[_vm._v("Paging")])],1)],1):_vm._e(),(!_vm.hasPagingEnabled && _vm.haslimitedManagerAccess)?_c('li',{staticClass:"sidebar-item"},[_c('a',{staticClass:"sidebar-link",attrs:{"target":"_blank","data-cy":"paging-learn-more-link","data-tooltip":"Paging - Learn More","data-position":"right","href":"https://claruscare.com/paging"}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'pager']}}),_c('span',{staticClass:"sr-only"},[_vm._v("Paging")])],1)]):_vm._e(),_c('li',{staticClass:"sidebar-item"},[_c('router-link',{staticClass:"sidebar-link",attrs:{"data-cy":"scheduling-link","data-tooltip":"Scheduling","data-position":"right","to":{
					name: 'app.schedules',
				}}},[_c('font-awesome-icon',{attrs:{"size":"2x","icon":['fal', 'calendar']}}),_c('span',{staticClass:"sr-only"},[_vm._v("Scheduling")])],1)],1),(_vm.isActiveProfile)?_c('li',{staticClass:"sidebar-item"},[_c('router-link',{staticClass:"sidebar-link flex",attrs:{"aria-current":"page","role":"link","data-cy":"calls-link","data-tooltip":"Messaging","data-position":"right","to":{
					name: 'app.chat',
				}}},[(_vm.hasUnreadMessages)?_c('div',{staticClass:"absolute top-0 right-0 h-6 w-6 text-white border-2 border-gray-300 rounded-full bg-red-500 z-2 text-xs flex justify-center items-center text-center"},[_vm._v(" "+_vm._s(_vm.unReadCount)+" ")]):_vm._e(),_c('font-awesome-icon',{attrs:{"icon":['fal', 'comments']}}),_c('span',{staticClass:"sr-only"},[_vm._v("Messaging")])],1)],1):_vm._e(),(_vm.hasOfficeManagerAccess)?_c('li',{staticClass:"sidebar-item"},[_c('router-link',{staticClass:"sidebar-link",attrs:{"data-cy":"providers-link","data-tooltip":"Providers","data-position":"right","to":{
					name: 'app.providers',
				}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'user-md']}}),_c('span',{staticClass:"sr-only"},[_vm._v("Providers")])],1)],1):_vm._e(),(_vm.hasOfficeManagerAccess)?_c('li',{staticClass:"sidebar-item"},[_c('router-link',{staticClass:"sidebar-link",attrs:{"exact-path":"","data-cy":"insights-link","aria-current":"insights","data-tooltip":"Insights","data-position":"right","to":{
					name: 'app.dashboard',
				}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'analytics']}}),_c('span',{staticClass:"sr-only"},[_vm._v("Insights")])],1)],1):_vm._e(),(_vm.hasOfficeManagerAccess)?_c('li',{staticClass:"sidebar-item"},[_c('router-link',{staticClass:"sidebar-link",attrs:{"href":"#","data-cy":"settings-link","data-tooltip":"Settings","data-position":"right","to":{
					name: 'app.settings',
				}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'cog']}}),_c('span',{staticClass:"sr-only"},[_vm._v("Settings")])],1)],1):_vm._e(),_c('li',{staticClass:"app-version"},[_vm._v("Version "+_vm._s(_vm.appVersion))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }